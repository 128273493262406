<template>
  <div class="head_top">
    <img src="../../../../public/images/官网底部文字对应的二级页面/新闻/新闻 banner.jpg" lazy width="100%" height="100%">

    <div  class="content_box">
      <div>
        <h2>用什么来记录孩子的真善美？</h2>
        <div class="date">时间: 2021-07-06 </div>

      </div>
      <p>孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝，哪怕只是远远的看着也特别满足，
        只差给孩子360度装上监控摄像头了，甚至想时刻跟孩子视频语音聊天。在这里，我们专业为您提供关爱的窗口—<a target="_blank" href="https://shop.vstarcam.cn/indoor-ip-cameras">智能网络摄像机</a>，让陪伴与呵护同在。
        智能监控摄像头既能看清萌宝各种表情、动作，又能与孩子全天畅聊，同时也能看到家人日常生活的点点滴滴。</p>
      <div class="img">
        <img src="../../../../public/images/底部导航/关于我们/新闻/用什么来记录孩子的真善美？/图片6.png" lazy width="100%" height="100%">
      </div>

      <p>一、当孩子在客厅活动时，手机就能清晰可见他们的世界是多么新奇有趣；若有危险即将出现时，智能摄像机及时语音告知孩子如何规避，防止可怕的事情发生。</p>
      <p>二、孩子偶尔也爱表现，比如当他们画画、阅读、唱歌或跳舞时，就希望爸爸妈妈能看到优秀的自己，直接对着智能监控摄像机就能轻松的跟爸妈分享快乐和忧伤，并能及时得到爸爸妈妈回应。</p>

      <img src="../../../../public/images/底部导航/关于我们/新闻/用什么来记录孩子的真善美？/图片4.png" lazy width="100%" height="100%">

      <p>三、家里电线老化导致短路，或突然出现烟雾火情时，智能监控摄像机能感应烟雾并报警，同时将警报信息推送至手机APP，及时控制火患。</p>
      <p>四、全国现有很多幼儿园教室里也安装智能网络摄像机，简直就是家长和老师们的福音。老师和园长可以通过客户端进行观看和管理视频，
        家长也可以在手机上查看孩子的一举一动，铁证如山，隐私安全，加密保护，高清录像回放，多人多平台同时观看。</p>

      <img src="../../../../public/images/底部导航/关于我们/新闻/用什么来记录孩子的真善美？/图片5.png" lazy width="100%" height="100%">

      <p>孩子时刻都在成长与变化，父母的陪伴与呵护，潜移默化地影响着孩子。一部智能网络摄像机就能帮您记录孩子成长过程里的真善美，留下无限美好且珍贵的回忆。
        智能监控摄像头通电即用，操作简单，老人孩子都会用，播放稳定流畅不卡顿，视频声音双录，非常值当。
        适用于家庭住宅，园区走廊，看护停车场，维护交通秩序，管理商场安全，看管酒吧安全等多种场所。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "用什么来记录孩子的真善美"
}
</script>

<style scoped>
h2{
  text-align: center;
}
p{
  text-indent: 2em;
}
a{
  text-decoration: none;
  color: #0077cc;
}
a:hover{
  cursor: pointer;
  color: red;
}
img{
}
.content_box{
  width: 80%;
  margin: auto;
}
.date{
  text-align: right;
}
</style>