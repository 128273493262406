import { render, staticRenderFns } from "./用什么来记录孩子的真善美.vue?vue&type=template&id=f57d9c26&scoped=true&"
import script from "./用什么来记录孩子的真善美.vue?vue&type=script&lang=js&"
export * from "./用什么来记录孩子的真善美.vue?vue&type=script&lang=js&"
import style0 from "./用什么来记录孩子的真善美.vue?vue&type=style&index=0&id=f57d9c26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f57d9c26",
  null
  
)

export default component.exports